import {createContext, useEffect, useState} from "react";
import localStorageService from "../logic/localStorageService";
import { Sounds } from "../enums/Sounds";
import AlertSound from "../audio/Alert.wav";
import Cute_InterfaceSound from "../audio/Cute_Interface.wav";
import Future_RingSound from "../audio/Future_Ring.wav";
import PlopSound from "../audio/Plop.wav";

const SoundContext = createContext({});

export const SoundProvider = ({children}) => {
    const soundList = [
        {
          id: Sounds.ALERT,
          name: "Alert",
          sound: AlertSound,
          active: false,
        },
        {
          id: Sounds.CUTE_INTERFACE,
          name: "Cute Interface",
          sound: Cute_InterfaceSound,
          active: false,
        },
        {
          id: Sounds.FUTURE_RING,
          name: "Future Ring",
          sound: Future_RingSound,
          active: false,
        },
        {
          id: Sounds.PLOP,
          name: "Plop",
          sound: PlopSound,
          active: false,
        },
      ];
    
      const [availableSounds, setAvailableSounds] = useState(soundList);
      const [defaultNotificationSound, setDefaultNotificationSound] = useState(null);
      // const init = useRef(true)
      // console.log(defaultNotificationSound)
      const saveSound = (soundId) => {
        localStorageService.saveSound(soundId);
        setAvailableSounds((prevSounds) =>
          prevSounds.map((sound) => {
            return {
              ...sound,
              active: sound.id === soundId
            }
          })
        );
      };
    
      
      const handleSetDefaultNotificationSound = (soundId) => {
        const sound = availableSounds.find(sound => sound.id === soundId)
        if(sound){
          saveSound(sound.id)
          console.log('sound: ', sound)
          setDefaultNotificationSound(sound.sound)
          // init.current = false
        } else {
          saveSound(Sounds.ALERT)
          setDefaultNotificationSound(AlertSound)
        }
      };
    
      const handleSetNewNotificationSound = (soundId) => {
        const sound = availableSounds.find(sound => sound.id === soundId)
    
        if(sound){
          saveSound(sound.id)
          handleSetDefaultNotificationSound(sound.id)
          const newSound = new Audio(sound.sound)
          newSound.play()      
        }
      };
    
      const getSavedSound = () => {
        return localStorageService.getSavedSound();
      };
    
      const playSound = () => {
        if (defaultNotificationSound) {
          new Audio(defaultNotificationSound).play();
        }
      };
    
      useEffect(() => {
        const savedNotificationSound = getSavedSound();
        console.log(savedNotificationSound)
        handleSetDefaultNotificationSound(savedNotificationSound);
      }, []);

    return (
        <SoundContext.Provider value={{ playSound, handleSetNewNotificationSound, availableSounds, defaultNotificationSound }}>
            {children}
        </SoundContext.Provider>
    )
}

export default SoundContext;