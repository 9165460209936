import {useLocation, Navigate} from 'react-router-dom'
import axios from '../api/axios'
import useAuth from './useAuth'

const useRefreshToken = () => {
    const {setAuth} = useAuth();
    const location = useLocation();

    const refresh = async() => {
        try{
            const response = await axios.get('/refresh',{
                withCredentials: true
            });
            setAuth(prev => {
                return {...prev,
                    user: JSON.parse(atob(response?.data?.split(".")[1])).UserInfo.userName,
                    role: JSON.parse(atob(response?.data?.split(".")[1])).UserInfo.userRole,
                    accessToken: response.data}
            });
            return response.data
        }catch(error){
            throw error
        }
        
    }
    return refresh;
}

export default useRefreshToken;