import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import React, { useEffect, useState } from "react";
import TableHeaders from "../../enums/TableHeaders";
import Dropdown from "../Dropdown/Dropdown";
import LazyLoadDataGrid from "../LazyLoadDataGrid/LazyLoadDataGrid";
import {useNavigate} from 'react-router-dom'

const AdminAllActionsLocation = () => {
  const axiosPrivate = useAxiosPrivate();
  const [pageSize, setPageSize] = useState(5);
  const [location, setLocation] = useState("calw");
  const [locations, setLocations] = useState(null);
  const [actionsFromLocation, setActionsFromLocation] = useState({
    data: [],
    total: 0,
  });
  const [actionsFromLocationPage, setActionsFromLocationPage] = useState(1);
  const navigate = useNavigate()

  useEffect(() => {
    axiosPrivate
      .get(`${process.env.REACT_APP_BASE_URL}/v1/area/locations`)
      .then((response) => {
        const locations = response.data.data.map((loc) => loc.location)
        setLocations(locations);
        setLocation(locations[0]);
      })
      .catch(err=>{
        if(err?.response?.status === 403){
          navigate('/login')
          return
        }
      })
  }, []);

  useEffect(() => {
    if (!actionsFromLocation) return;

    axiosPrivate
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/useraction/location/${location}/?limit=${pageSize}&&page=${actionsFromLocationPage}`
      )
      .then((response) => {
        setActionsFromLocation({
          data: [...response.data.data.rows],
          total: response.data.data.total,
        });
      })
      .catch((err) => {
        if(err?.response?.status === 403){
          navigate('/login')
          return
        }
        console.log(err)
      });
  }, [pageSize, actionsFromLocationPage]);

  useEffect(() => {
    axiosPrivate
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/useraction/location/${location}/?limit=${pageSize}&&page=1`
      )
      .then((response) => {
        setActionsFromLocation({
          data: response.data?.data?.rows || [],
          total: response.data.data?.total || 0,
        });
      })
      .catch((err) => {
        if(err?.response?.status === 403){
          navigate('/login')
          return
        }
        console.log(err)
      });
      // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <h3>Alle Aktionen bestimmter Standorte</h3>
      {locations && (
        <Dropdown
          menuArray={Object.values(locations)}
          value={location}
          setValue={setLocation}
        />
      )}
      <LazyLoadDataGrid
        columns={TableHeaders.adminLatestActions}
        rows={actionsFromLocation}
        setPage={setActionsFromLocationPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default AdminAllActionsLocation;
