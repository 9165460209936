import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CheckboxList from '../List/List'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SimpleInput from '../SimpleInput/SimpleInput';
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from 'react-router-dom'
import EmptySuperior from '../../enums/EmptySuperior';
import { v4 as uuidv4 } from 'uuid'

const SuperiorAssignment = () => {
    const axiosPrivate = useAxiosPrivate();
    const [data,setData] = useState(EmptySuperior)
    const [superiors,setSuperiors] = useState([])
    const [visibleInput, setVisibleInput] = useState(false)
    const [visibleSend,setVisibleSend] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        axiosPrivate.get(`/superiors`)
        .then((response)=>{
            setSuperiors(response?.data?.data || [])
        })
        .catch(err => {
            if(err?.response?.status === 403){
                navigate('/login')
                return
              }
            console.log(err)
        })
    },[])

    const handleChange = (e) => {
        setData(lastVal => ({...lastVal,[e.target.name]:e.target.value}))
    }

    const handleAdd = (e) => {
        setVisibleInput(true)
        setVisibleSend(true)
    }

    const handleSend = () => {
        const id = uuidv4();
        const oldSuperiors = superiors
        const newSuperior = {id,...data}
        setSuperiors([...superiors,newSuperior])
        axiosPrivate.post(`/superiors`,newSuperior)
        .then(()=>{setData(EmptySuperior)})
        .catch(err=>{
            console.log(err)
            if(err?.response?.status === 403){
                navigate('/login')
                return
              }
            setSuperiors(oldSuperiors)
            console.log(err)
            alert('Something went wrong')
        })
        setVisibleInput(false)
        setVisibleSend(false)
    }
    
    const deleteSuperior = (superiorId) => {
        const oldSuperiors = superiors
        const newSuperiors = superiors.filter(el=>el.id !== superiorId)
        setSuperiors(newSuperiors)
        axiosPrivate.delete(`/superiors/?id=${superiorId}`)
        .catch(error=>{
        setSuperiors(oldSuperiors)
        console.log(error)
        })
      }
      
  return (
    <>
        <h3>Zuordnung Mitarbeiter - Vorgesetzter</h3>
        <CheckboxList items={superiors} deleteCb={deleteSuperior} content="superiors"/>
        <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row-reverse'}}>
            {!visibleSend ?
                <IconButton aria-label="comments" sx={{marginTop:'5px'}} onClick={handleAdd}>
                    <AddCircleIcon sx={{color:'#009ee3'}} fontSize="large" />
                </IconButton>
                :
                <IconButton aria-label="comments" sx={{marginTop:'5px',maxHeight:'50px'}} onClick={handleSend}>
                    <SendIcon sx={{color:'green'}} fontSize="large" />
                </IconButton>
            }
                        
            {visibleInput&&
            <div className="row">
                <div className="col-md-12">
                    <SimpleInput 
                        label="Mitarbeiter Vorname" 
                        name="user_firstname" 
                        value={data.user_firstname}
                        setValue={handleChange}
                        sx={{marginRight:'25px',marginTop:'15px'}}
                    />
                    <SimpleInput 
                        label="Mitarbeiter Nachname" 
                        name="user_lastname" 
                        value={data.user_lastname}
                        setValue={handleChange}
                        sx = {{marginTop:'15px'}}
                    />
                    <br/>
                    <SimpleInput 
                        label="Mitarbeiter Nummer" 
                        name="user_number" 
                        value={data.user_number}
                        setValue={handleChange}
                        sx={{marginRight:'25px',marginTop:'15px'}}
                    />
                    <br/>
                    <SimpleInput
                        label="Vorgesetzter Vorname"
                        name="superior_firstname"
                        value={data.superior_firstname}
                        setValue={handleChange}
                        sx={{marginTop:'15px',marginRight:'25px'}}
                    />
                    <SimpleInput
                        label="Vorgesetzter Nachname"
                        name="superior_lastname"
                        value={data.superior_lastname}
                        setValue={handleChange}
                        sx={{marginTop:'15px'}}
                    />
                    <br/>
                    <SimpleInput
                        label="Vorgesetzter Nummer" 
                        name="superior_number" 
                        value={data.superior_number}
                        setValue={handleChange}
                        sx={{marginTop:'15px',marginRight:'25px'}}
                    />
                    <SimpleInput 
                        label="Vorgesetzter Mail" 
                        name="superior_mail" 
                        value={data.superior_mail}
                        setValue={handleChange}
                        sx={{marginTop:'15px'}}
                    />
                </div>
            </div>
            }
        </div>
    </>
  )
}

export default SuperiorAssignment