import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CheckboxList from '../List/List'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SimpleInput from '../SimpleInput/SimpleInput';
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

const AdminRules = () => {
    const axiosPrivate = useAxiosPrivate();
    const [val,setVal] = useState("")
    const [rules,setRules] = useState([])
    const [visibleInput, setVisibleInput] = useState(false)
    const [visibleSend,setVisibleSend] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        axiosPrivate.get(`${process.env.REACT_APP_BASE_URL}/v1/access`)
        .then((response)=>{
            setRules(response.data.data)
        })
        .catch(err => {
            if(err?.response?.status === 403){
                navigate('/login')
                return
              }
            console.log(err)
        })
    },[])

    const handleAdd = (e) => {
        setVisibleInput(true)
        setVisibleSend(true)
    }

    const handleSend = () => {
        const id = uuidv4();
        const oldRules = rules
        const newRule = {id,name: val}
        setRules([...rules,newRule])
        axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}/v1/access`,newRule)
        .then(()=>setVal(""))
        .catch(err=>{
            if(err?.response?.status === 403){
                navigate('/login')
                return
              }
            setRules(oldRules)
        })
        setVisibleInput(false)
        setVisibleSend(false)
    }

    const deleteRule = (id) => {
        const oldRules = rules
        const newRules = rules.filter(el=>el.id !== id)
        setRules(newRules)
        axiosPrivate.delete(`/access/?id=${id}`)
        .catch(error=>{
        setRules(oldRules)
        console.log(error)
        })
      }

  return (
    <>
        <h3>Einlassregeln</h3>
        <CheckboxList items={rules} deleteCb={deleteRule} content="rules"/>
        <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row-reverse'}}>
            {!visibleSend ?
                <IconButton aria-label="comments" sx={{marginTop:'5px'}} onClick={handleAdd}>
                    <AddCircleIcon sx={{color:'#009ee3'}} fontSize="large" />
                </IconButton>
                :
                <IconButton aria-label="comments" sx={{marginTop:'5px'}} onClick={handleSend}>
                    <SendIcon sx={{color:'green'}} fontSize="large" />
                </IconButton>
            }
                        
            {visibleInput&&<SimpleInput label="Neue Regel" value={val} setValue={(e)=>setVal(e.target.value)}/>}
        </div>
    </>
  )
}

export default AdminRules