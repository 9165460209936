import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import capitalizeFirstLetter from "../logic/capitalizeFirstLetter";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { Tooltip } from "@mui/material";

const TableHeaders = {
  adminLatestActions: [
    {
      field: "firstName",
      headerName: "Vorname",
      width: 250,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "lastName",
      headerName: "Nachname",
      width: 250,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "action",
      headerName: "Aktion",
      width: 110,
      renderCell: (params) => {
        return params.value === "left" ? (
          <LogoutIcon style={{ color: "red" }} fontSize="large" />
        ) : (
          <LoginIcon style={{ color: "green" }} fontSize="large" />
        );
      },
    },
    {
      field: "location",
      headerName: "Standort",
      width: 160,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "entrance",
      headerName: "Eingang",
      width: 450,
      renderCell: (params) => {
        return <Tooltip title={capitalizeFirstLetter(params.value)}>
          <span className="table-cell-truncate">{capitalizeFirstLetter(params.value)}</span>
        </Tooltip>
      },
    },
    {
      field: "denied",
      headerName: "Zutritt",
      width: 160,
      renderCell: (params) => {
        return params.value === 0 || params.value === false ? (
          <CheckCircleIcon style={{ color: "green" }} fontSize="large" />
        ) : (
          <DoNotDisturbOnIcon style={{ color: "red" }} fontSize="large" />
        );
      },
    },
    {
      field: "timestamp",
      headerName: "Datum",
      width: 250,
      renderCell: (params) => (params.value = moment(params.value).format("DD.MM.YYYY HH:mm:ss")),
    },
  ],
  adminAllTimesArea: [
    {
      field: "firstName",
      headerName: "Vorname",
      width: 150,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "lastName",
      headerName: "Nachname",
      width: 150,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "entered",
      headerName: "Eingestempelt",
      width: 200,
      renderCell: (params) => (params.value !== null ? params.value = moment(params.value).format("DD.MM.YYYY HH:mm:ss") : "N/A"),
    },
    {
      field: "nxttime",
      headerName: "Ausgestempelt",
      width: 200,
      renderCell: (params) => (params.value !== null ? params.value = moment(params.value).format("DD.MM.YYYY HH:mm:ss") : "N/A"),
    },
    {
      field: "diff",
      headerName: "Stempelzeit (Tage : Stunden : Minuten : Sekunden)",
      width: 380,
      align: 'right',
      renderCell: (params) => (params.value !== null ? params.value = capitalizeFirstLetter(params.value) : "N/A"),
    }
  ],
  userLatestActions: [
    {
      field: "firstName",
      headerName: "Vorname",
      width: 150,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "lastName",
      headerName: "Nachname",
      width: 150,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "action",
      headerName: "Aktion",
      width: 110,
      renderCell: (params) => {
        return params.value === "left" ? (
          <LogoutIcon style={{ color: "red" }} fontSize="large" />
        ) : (
          <LoginIcon style={{ color: "green" }} fontSize="large" />
        );
      },
    },
    {
      field: "location",
      headerName: "Standort",
      width: 160,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "entrance",
      headerName: "Eingang",
      width: 450,
      renderCell: (params) => {
        return <Tooltip title={capitalizeFirstLetter(params.value)}>
          <span className="table-cell-truncate">{capitalizeFirstLetter(params.value)}</span>
        </Tooltip>
      },
    },
    {
      field: "timestamp",
      headerName: "Datum",
      width: 250,
      renderCell: (params) => (params.value = moment(params.value).format("DD.MM.YYYY HH:mm:ss")),
    },
  ],
  foreignChips: [
    {
      field: "uid",
      headerName: "Transponder UID",
      width: 200,
    },
    {
      field: "timestamp",
      headerName: "Datum",
      width: 160,
      renderCell: (params) => (params.value = moment(params.value).format("DD.MM.YYYY HH:mm:ss")),
    },
  ],
  devices: [
    {
      field: "ip",
      headerName: "IP-Adresse",
      width: 150,
    },
    {
      field: "location",
      headerName: "Standort",
      width: 150,
      renderCell: (params) => (params.value = capitalizeFirstLetter(params.value)),
    },
    {
      field: "entrance",
      headerName: "Eingang",
      width: 450,
      renderCell: (params) => {
        return <Tooltip title={capitalizeFirstLetter(params.value)}>
          <span className="table-cell-truncate">{capitalizeFirstLetter(params.value)}</span>
        </Tooltip>
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return params.value === "ok" ? (
          <CircleIcon style={{ color: "green" }} fontSize="large" />
        ) : (
          <CircleIcon style={{ color: "red" }} fontSize="large" />
        );
      },
    },
    {
      field: "timestamp",
      headerName: "Datum",
      width: 160,
      renderCell: (params) => (params.value = moment(params.value).format("DD.MM.YYYY HH:mm:ss")),
    },
  ],
};

export default TableHeaders;
