import axios from 'axios'
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/v1`

export default axios.create({
    baseURL: BASE_URL,
    withCredentials: true
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
})