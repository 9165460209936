import TextField from '@mui/material/TextField';

export default function SimpleInput(props) {
  const {value,setValue,sx,label="",name=undefined} = props

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <TextField
        id="outlined-basic"
        label={label} 
        name = {name}
        variant="outlined"
        sx={sx}
        value={value}
        onChange={setValue}
    />
  );
}