import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './lazyLoadDataGrid.css'

const LazyLoadDataGrid = (props) => {

  const{rows,columns,setPage,pageSize,setPageSize} = props

  return (<>
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rowCount={rows && rows.total}
        rows={rows?.data ? rows.data : ''}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[5,10,15,20,25,30]}
        onPageSizeChange={p=>setPageSize(p)}
        onPageChange={(p)=>setPage(p+1)}
        paginationMode="server"
        disableSelectionOnClick
        disableColumnSelector
        sx={{ fontSize: "1em" }}
      />
    </div>
    </>
  );
}
export default LazyLoadDataGrid