const EmptySuperior = {
    user_firstname:'',
    user_lastname:'',
    user_number:'',
    superior_firstname:'',
    superior_lastname:'',
    superior_number:'',
    superior_mail:''
}

export default EmptySuperior