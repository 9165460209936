import { useState } from "react";
import Login from "./login/Login";
import Home from "./home/Home";
import Pages from "./enums/Pages";
import Admin from "./admin/Admin";
import Navbar from "./general/navbar/Navbar";
import RequireAuth from "./logic/requireAuth";
import PersistentLogin from "./logic/persistentLogin";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        {/* public routes */}
        <Route path="/login" element={<Login />} />

        {/* protected routes */}
        <Route element={<PersistentLogin />}>
          <Route element={<Navbar />}>
            <Route element={<RequireAuth allowedRoles={["user", "admin"]} />}>
              <Route exact path="/" element={<Home />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="/admin" element={<Admin />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
