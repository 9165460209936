import { useEffect, useState, useRef } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
// import { createPusher } from "../logic/createPusher";
import usePusher from '../hooks/usePusher'
import "./home.css";
import AWN from "awesome-notifications";
import capitalizeFirstLetter from "../logic/capitalizeFirstLetter";
import TableHeaders from "../enums/TableHeaders";
import {useNavigate} from "react-router-dom";
import useSound from "../hooks/useSound";
let globalOptions = {
  position: "bottom-right",
  labels: { success: "Aktion", alert: "Aktion" },
  icons: {
    success: "arrow-right-to-bracket",
    alert: "arrow-right-from-bracket",
  },
  durations: { global: 0 },
};

const Home = (props) => {
  let notifier = new AWN(globalOptions);
  const axiosPrivate = useAxiosPrivate();
  const { error, userLocation } = props;
  const [allActions, setAllActions] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const {playSound, defaultNotificationSound} = useSound()
  const navigate = useNavigate()
  const pusherChannel = useRef();
  const createPusher = usePusher("private-dev-securitySystem")
  
  const bindPusherEvents = (channel, event) => {
    channel.bind(event, async function (data) {
      try {
        let newActions = allActions
        if(newActions.some((act)=> act.personnelNumber === data.personnelNumber)){
          newActions = allActions.filter(el=>el.personnelNumber !== data.personnelNumber)
        }
        
        setAllActions([data,...newActions])
        if (data.denied === true){
          notifier.warning(
            `${capitalizeFirstLetter(data.firstName)} ${capitalizeFirstLetter(data.lastName)} hat Zutritt zum Gebäude ohne passende Berechtigung!!!`
          )
        }
        else if (data.action === "entered")
          notifier.success(
            `${capitalizeFirstLetter(data.firstName)} ${capitalizeFirstLetter(data.lastName)} hat ${capitalizeFirstLetter(data.location)} ${capitalizeFirstLetter(data.entrance)} betreten`
          );
        else {
          notifier.alert(
            `${capitalizeFirstLetter(data.firstName)} ${capitalizeFirstLetter(data.lastName)} hat ${capitalizeFirstLetter(data.location)} ${capitalizeFirstLetter(data.entrance)} verlassen`
          );
        }
        playSound()
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getCurrentActions = async () => {
    return axiosPrivate
      .get(`/useraction/current${userLocation ? '?location='+userLocation : ''}`)
      .then((response) => {
        setAllActions(response.data.data || []);
      })
      .catch((err) => {
        console.log(err)
        throw err;
      });
  };

  useEffect(() => {
    (async () => {
      try {
        pusherChannel.current = await createPusher;
        await getCurrentActions();
      } catch (err) {
        console.log(err)
        if(err === 'unauthorized'){
          navigate('/login')
          return
        }
        if(err?.response?.status === 403){
          navigate('/login')
          return
        }
        console.error(error);
      }
    })();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!pusherChannel.current) return;
    console.log('binded')
    bindPusherEvents(pusherChannel.current, "userAction");
    return () => {
      pusherChannel.current.unbind("userAction");
    };
    
    // eslint-disable-next-line
  }, [allActions, defaultNotificationSound]);

  return (
    <div style={{paddingTop:'90px'}}>
      {error && (
        <Alert severity="error">{`${error.code} ${error.message}`}</Alert>
      )}
      {allActions && (
        <div>
          <div
            className="row"
            style={{
              border: "0px",
              marginRight: 0,
              marginLeft: 0,
              maxWidth: "100%",
            }}
          >
            <div className="col-md-12">
              <div style={{ height: 475, width: "100%" }} className="mt-3">
                <DataGrid
                  rows={allActions}
                  columns={TableHeaders.adminLatestActions}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  sx={{ fontSize: "1.5em" }}
                  // sx = {{ background: 'grey', color:'white'}}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
