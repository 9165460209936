import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import "./logic/axiosInterceptor";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./general/navbar/Navbar";
import Login from "@mui/icons-material/Login";
import { SoundProvider } from "./context/SoundProvider";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <BrowserRouter>
      <AuthProvider>
        <SoundProvider>
          <Routes>
            <Route path="/*" element={<App />} />
            {/* <Route path="/*" element={<App />} /> */}
          </Routes>
        </SoundProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
  // {/* </React.StrictMode> */}
);
