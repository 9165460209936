import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import "./login.css";
import Loading from "../enums/Loading";
import axios from "../api/axios";
import { Link, useNavigate, useLocation} from 'react-router-dom';

import LoadingScreen from "../general/loadingScreen/LoadingScreen";
import Pages from "../enums/Pages";
import Alert from "@mui/material/Alert";
import { errorHandler } from "../logic/errorHandler";
import localStorageService from "../logic/localStorageService";
const LOGIN_URL = "/user";

const Login = (props) => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/"

  const [input, setInput] = useState({ username: "", password: "" });
  const [pageLoading, setPageLoading] = useState(Loading.Inactive);
  const [loading, setLoading] = useState(Loading.Inactive);
  const [errMsg,setErrMsg] = useState('')

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(Loading.Active);
    try {
      const response = await axios.post(LOGIN_URL, input, {
      });
      const accessToken = response?.data?.data?.accessToken;
      const role = JSON.parse(atob(accessToken.split(".")[1])).UserInfo.userRole;
      console.log(role)
      setAuth({user: input.username,pwd:input.password,role,accessToken})
      navigate(from, {replace: true});
    } catch (err) {
      console.log(err)
      if(!err?.response){
        setErrMsg('No Server Response')
      } else if (err.response.status === 400){
        setErrMsg('Missing Username or Password')
      } else if (err.response?.status === 401){
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
    } finally{
      setLoading(Loading.Inactive);
    }

    // axios.post(LOGIN_URL,input,{ withCredentials: true })
    // .then((response) =>{
    //   localStorageService.setAccessToken(response.data.data.accessToken)
    //   console.log(localStorageService.getAccessTokenData())
    //   setUserRole(localStorageService.getAccessTokenData().userRole)
    //   setUserLocation(localStorageService.getAccessTokenData().assignedLocation)
    //   setUserName(localStorageService.getAccessTokenData().userName)
    //   setError(null)
    //   setLoading(Loading.Inactive)
    //   setChosenPage(Pages.Home)
    // })
    // .catch(err => {
    //   errorHandler(err,setError)
    //   setLoading(Loading.Inactive)
    // })
  };

  // useEffect(() => {
  //   setPageLoading(Loading.Active);
  //   axios
  //     .get(process.env.REACT_APP_BASE_URL + "/v1/user", {
  //       withCredentials: true,
  //     })
  //     .then(() => {
  //       setUserRole(localStorageService.getAccessTokenData().userRole);
  //       setUserLocation(
  //         localStorageService.getAccessTokenData().assignedLocation
  //       );
  //       setUserName(localStorageService.getAccessTokenData().userName);
  //       setError(null);
  //       setPageLoading(Loading.Inactive);
  //       setChosenPage(Pages.Home);
  //     })
  //     .catch((err) => {
  //       setPageLoading(Loading.Error);
  //       console.error(err);
  //     });
  //   // eslint-disable-next-line
  // }, []);

  const togglePersist = () => {
    setPersist(prev => !prev)
  }

  useEffect(()=>{
    localStorage.setItem('persist',persist)
  },[persist])

  return (
    <>
      {pageLoading === Loading.Active && <LoadingScreen />}
      <section className="vh-100 test">
        <div className="container py-5 h-100">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-8 col-lg-7 col-xl-6">
              <img
                src="https://cdn-icons-png.flaticon.com/512/2092/2092745.png"
                className="img-fluid"
                alt="Phone"
              />
            </div>
            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
              <form onSubmit={handleSubmit}>
                {/* <!-- Email input --> */}
                <div className="form-outline mb-4">
                  <input
                    type="text"
                    id="name"
                    className="form-control form-control-lg"
                    name="username"
                    value={input.username}
                    onChange={changeHandler}
                    required
                  />
                  <label className="form-label" htmlFor="name">
                    Username
                  </label>
                </div>

                {/* <!-- Password input --> */}
                <div className="form-outline mb-2">
                  <input
                    type="password"
                    id="pw"
                    className="form-control form-control-lg"
                    name="password"
                    value={input.password}
                    onChange={changeHandler}
                    required
                  />
                  <label className="form-label" htmlFor="pw">
                    Passwort
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    id="persist"
                    className="form-check-input"
                    onChange={togglePersist}
                    checked={persist}
                  />
                  <label className="form-check-label" htmlFor="persist">
                    Trust Device
                  </label>
                </div>
                {/* <input type="checkbox" className="form-control form-control-lg"/> */}
                {errMsg && (
                  <Alert severity="error">{`${errMsg}`}</Alert>
                )}
                <div className="d-flex justify-content-around align-items-center mb-2"></div>

                {/* <!-- Submit button --> */}
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block"
                >
                  {loading === Loading.Active && (
                    <>
                      <span
                        className="spinner-border spinner-border-sm mb-1"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Loading...
                    </>
                  )}
                  {(loading === Loading.Inactive ||
                    loading === Loading.Error) &&
                    "Login"}
                </button>
              </form>
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
            <img src="./img/logo_white.png" width="200px" alt="logo" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
