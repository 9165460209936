import React from 'react'
import './loadingScreen.css'

const LoadingScreen = () => {
  return (
    <>
    
      <div className="loader">
          <div></div>
          <div></div>
      </div>
      <div className="loadingBackground"></div>
      </>
  )
}

export default LoadingScreen