import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import style from './list.module.css'
import capitalizeFirstLetter from '../../logic/capitalizeFirstLetter';

export default function CheckboxList(props) {

  const {items,deleteCb,content} = props

  return (
    <List className={style.whitelist} sx={{ width: '100%',  maxHeight: 360, overflowY:'scroll', bgcolor: 'background.paper' }}>
      {items && items.map((el,index) => {
        return (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton edge="end" aria-label="comments" onClick={()=>deleteCb(el.id)}>
                <HighlightOffIcon sx={{color:'red'}} />
              </IconButton>
            }
            disablePadding
          >
            {/* <ListItemButton role={undefined}  dense> */}
              {content === "rules" && <ListItemText primary={el.name} />}
              {content === "superiors" && <ListItemText 
              primary={`
                 ${el.user_number}
                 ${capitalizeFirstLetter(el.user_firstname)}
                 ${capitalizeFirstLetter(el.user_lastname)}
                 <—>
                 ${el.superior_number}
                 ${capitalizeFirstLetter(el.superior_firstname)}
                 ${capitalizeFirstLetter(el.superior_lastname)}
                 (${el.superior_mail})
              `} />}
            {/* </ListItemButton> */}
          </ListItem>
        );
      })}
    </List>
  );
}