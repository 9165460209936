import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './defaultDataGrid.css'

const DefaultDataGrid = (props) => {

  const{rows,columns} = props
  return (<>
    <div style={{ height: 400, width: '100%' }} className="grid">
      <DataGrid
        className='grid'
        rows={rows ? rows : []}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        disableColumnSelector
        sx={{ fontSize: "1em" }}
      />
    </div>
    </>
  );
}
export default DefaultDataGrid