import React, { useEffect, useState } from "react";
import TableHeaders from "../../enums/TableHeaders";
import TableSelection from "../../enums/TableSelection";
import DefaultDataGrid from "../DefaultDataGrid/DefaultDataGrid";
import Dropdown from "../Dropdown/Dropdown";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const AdminAllTimesArea = () => {
  const axiosPrivate = useAxiosPrivate();
  //   const [latestActionsFilter,setLatestActionsFilter] = useState(TableSelection.AdminLatestActions[0].val)
  const [eventTimes, setEventTimes] = useState(null);
  const [area, setArea] = useState(null);
  const [areas, setAreas] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axiosPrivate
      .get(`${process.env.REACT_APP_BASE_URL}/v1/area`)
      .then((response) => {
        setAreas(
          response.data.data.map((el) => {
            return el.area;
          })
        );
        setArea(response.data.data[0].area);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, []);

  useEffect(() => {
    if (!area) return;
    axiosPrivate
      .get(`${process.env.REACT_APP_BASE_URL}/v1/area/areaTimes?area=${area}`)
      .then((response) => setEventTimes(response.data))
      .catch((err) => {
        if (err?.response?.status === 403) {
          navigate("/login");
          return;
        }
        console.log(err);
      });
  }, [area]);

  
  return (
    <>
      <h3>Zeiten Überblick</h3>
      {areas && <Dropdown menuArray={areas} value={area} setValue={setArea} />}
      <DefaultDataGrid columns={TableHeaders.adminAllTimesArea} rows={eventTimes} />
    </>
  );
};

export default AdminAllTimesArea;
