import { useContext, useEffect, useRef, useState } from "react";
import AlertSound from "../audio/Alert.wav";
import Cute_InterfaceSound from "../audio/Cute_Interface.wav";
import Future_RingSound from "../audio/Future_Ring.wav";
import PlopSound from "../audio/Plop.wav";
import { Sounds } from "../enums/Sounds";
import localStorageService from "../logic/localStorageService";
import SoundContext from "../context/SoundProvider";

const useSound = () => {
  return useContext(SoundContext)
};

export default useSound;
