import React, { useEffect, useState } from 'react'
import TableHeaders from '../../enums/TableHeaders'
import TableSelection from '../../enums/TableSelection'
import DefaultDataGrid from '../DefaultDataGrid/DefaultDataGrid'
import Dropdown from '../Dropdown/Dropdown'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import {useNavigate} from 'react-router-dom'

const AdminLatestActions = () => {
  const axiosPrivate = useAxiosPrivate();
  const [latestActionsFilter,setLatestActionsFilter] = useState(TableSelection.AdminLatestActions[0].val)
  const [latestActions,setLatestActions] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    if(latestActionsFilter === TableSelection.AdminLatestActions[1].val){
        axiosPrivate.get(`${process.env.REACT_APP_BASE_URL}/v1/useraction/current`)
        .then(response=>setLatestActions(response.data.data))
        .catch(err => {
          if(err?.response?.status === 403){
            navigate('/login')
            return
          }
        })
        return
    }
    
    axiosPrivate.get(`${process.env.REACT_APP_BASE_URL}/v1/useraction/latestactions`)
    .then(response=>setLatestActions(response.data.data))
    .catch(err => {
      if(err?.response?.status === 403){
        navigate('/login')
        return
      }
      console.log(err)
    })
  }, [latestActionsFilter])

  return (
    <>
        <div style={{display:'flex',justifyContent:'space-between'}}>
            <h3>Neuste Aktionen</h3>
            {<Dropdown menuArray={TableSelection.AdminLatestActions} value={latestActionsFilter} setValue={setLatestActionsFilter}/>}
        </div>
        <DefaultDataGrid columns={TableHeaders.adminLatestActions} rows={latestActions}/>
    </>
  )
}

export default AdminLatestActions