import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import capitalizeFirstLetter from '../../logic/capitalizeFirstLetter';

export default function Dropdown(props) {
    const {menuArray,value,setValue} = props
    
    return (
    <div>
        <FormControl sx={{ mb:1,width:'auto', '& legend': { display: 'none' },'& fieldset': { top: 2 } }} variant="outlined">
        <Select
            value={value}
            onChange={(e)=>setValue(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
        >
            {menuArray.map((el,index)=><MenuItem value={el.val || el} key={index}>{capitalizeFirstLetter(el.text || el)}</MenuItem>)}
        </Select>
        </FormControl>
    </div>
    );
}