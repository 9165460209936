const localStorageService =  {
    getAccessToken: ()=>{
        const accessToken = localStorage.getItem('accessToken')
        if(accessToken){
            return JSON.parse(accessToken)
        }
    },
    getRefreshToken: ()=>{
        const refreshToken = localStorage.getItem('refreshToken')
        if(refreshToken){
            return JSON.parse(refreshToken)
        }
    },
    getAccessTokenData: ()=>{
        const accessToken = localStorage.getItem('accessToken')
        if(accessToken){
            const tokenArr = accessToken.split(".")
            const decoded = JSON.parse(atob(tokenArr[1]))
            delete decoded.iat
            delete decoded.exp
            return decoded
        }
    },
    setAccessToken: (accessToken)=>{
        localStorage.setItem('accessToken', JSON.stringify(accessToken))
    },
    removeAccessToken : ()=>{
        localStorage.removeItem('accessToken')
    },
    getSavedSound: () => localStorage.getItem('notificationSound'),
    saveSound: (id) => localStorage.setItem('notificationSound', id)
}

export default localStorageService