import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SecurityIcon from "@mui/icons-material/Security";
import { Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Collapse, List } from "@mui/material";
import useSound from "../../hooks/useSound";
import { GridCheckIcon } from "@mui/x-data-grid";

const Navbar = (props) => {
  const { auth } = useAuth();
  const { availableSounds, handleSetNewNotificationSound} = useSound();
  const navigate = useNavigate();
  const logout = useLogout();
  const [open, setOpen] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const pages = ["Home"];
  auth?.role === "admin" && pages.push("Admin");
  const settings = ["Logout", "Sounds"];
  const pageHandler = (page) => {
    switch (page) {
      case "Home":
        navigate("/");
        break;
      case "Admin":
        navigate("/admin");
        break;
      default:
        throw new Error();
    }
    // setChosenPage(Pages.Home);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const handleLogout = async (e) => {
    e.stopPropagation();
    await logout();
    navigate("/login");
  };

  const handleSoundClick = (e,id) => {
    e.stopPropagation();
    handleSetNewNotificationSound(id);
  };

  const handleSoundMenu = (e) => {
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <>
      <AppBar position="fixed">
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <SecurityIcon sx={{ display: { xs: "none", md: "flex", color: "#009ee3" }, mr: 1 }} fontSize="large" />

            <img src="./img/logo_white.png" width="8%" alt="logo" />

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page}>
                    <Typography textAlign="center" onClick={() => pageHandler(page)}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            <Box
              variant="h5"
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {/* <img src="./img/logo_white.png" width="100px"/> */}
            </Box>
            {/* <img src="./img/logo_white.png" width="10%"/> */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mx: 2 }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => pageHandler(page)}
                  sx={{ my: 2, color: "white", display: "block", fontSize: "1.5em" }}
                >
                  <span>{page}</span>
                </Button>
              ))}
            </Box>

            <Box>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>{auth?.user?.charAt(0).toUpperCase()}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClick={handleCloseUserMenu}
              >
                {settings.map((setting) => {
                  return (
                    <div
                      key={setting}
                      // onMouseEnter={setting === "Sounds" ? handleOpenSoundMenu : null}
                      // onMouseLeave={setting === "Sounds" ? handleCloseSoundMenu : null}
                      onClick={setting === "Logout" ? handleLogout : null}
                    >
                      <MenuItem
                        onClick={setting === "Sounds" ? (e) => handleSoundMenu(e) : null}
                        // onMouseLeave={setting === "Sounds" ? () => setOpen(false) : null}
                      >
                        <Typography textAlign="center">{setting}</Typography>
                        {setting === "Sounds" && (open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                      </MenuItem>
                      {setting === "Sounds" && (
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {availableSounds.map((child, key) => (
                              <MenuItem key={key} onClick={(event) => handleSoundClick(event, child.id)}>
                                {child.name}
                                {child.active && <GridCheckIcon />}
                              </MenuItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </div>
                  );
                })}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
};
export default Navbar;
