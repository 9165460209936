import React, { useEffect, useState } from "react";
import TableHeaders from "../enums/TableHeaders";
import DefaultDataGrid from "../components/DefaultDataGrid/DefaultDataGrid";
import AdminLatestActions from "../components/AdminLatestActions/AdminLatestActions";
import AdminRules from "../components/AdminRules/AdminRules";
import AdminAllActionsLocation from "../components/AdminAllActionsLocation/AdminAllActionsLocation";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import SuperiorAssignment from "../components/SuperiorAssignment/SuperiorAssignment";
import AdminAllTimesArea from "../components/AdminAllTimesArea/AdminAllTimesArea";

const Admin = () => {
  const [devices, setDevices] = useState(null);
  const [foreignChips, setForeignChips] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        axiosPrivate.get(`${process.env.REACT_APP_BASE_URL}/v1/devices`).then((response) => {
          setDevices(response.data.data);
        });
        axiosPrivate
          .get(`${process.env.REACT_APP_BASE_URL}/v1/foreignChips`)
          .then((response) => setForeignChips(response.data.data))
          .catch((err) => console.log(err));
      } catch (err) {
        if (err?.response?.status === 403) {
          navigate("/login");
          return;
        }
        console.log(err);
      }
    })();
  }, []);

  return (
    <div style={{ paddingTop: "90px" }}>
      <div
        className="row mt-3"
        style={{
          border: "0px",
          marginRight: 10,
          marginLeft: 10,
          maxWidth: "100%",
        }}
      >
        <div className="col-md-8">
          {/* <AdminLatestActions /> */}
          <SuperiorAssignment />
        </div>
        <div className="col-md-3 ml-auto">
          <AdminRules />
        </div>
      </div>
      <hr style={{ background: "grey" }} />
      <div
        className="row mt-4"
        style={{
          border: "0px",
          marginRight: 10,
          marginLeft: 10,
          maxWidth: "100%",
        }}
      >
        <div className="col-md-12">
          <AdminLatestActions />
          {/* <SuperiorAssignment/> */}
        </div>
      </div>

      <div
        className="row mt-3"
        style={{
          border: "0px",
          marginRight: 10,
          marginLeft: 10,
          maxWidth: "100%",
        }}
      >
        <div className="col-md-12">
          <AdminAllActionsLocation />
        </div>
      </div>
      <div
        className="row mt-3"
        style={{
          border: "0px",
          marginRight: 10,
          marginLeft: 10,
          maxWidth: "100%",
        }}
      >
        <div className="col-md-6">
          <h3>Gerätestatus</h3>
          <DefaultDataGrid rows={devices} columns={TableHeaders.devices} />
          <br />
        </div>
        <div className="col-md-6">
          <h3>Unbekannte Transponder</h3>
          <DefaultDataGrid rows={foreignChips} columns={TableHeaders.foreignChips} />
          <br />
        </div>
        <div className="col-md-12">
          <AdminAllTimesArea />
        </div>
      </div>
    </div>
  );
};

export default Admin;
