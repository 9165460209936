import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Pusher from "pusher-js"

const usePusher = (pusherChannel) => {
    const axiosPrivate = useAxiosPrivate();
    
    return new Promise((resolve, reject) =>
    {
    Pusher.logToConsole = true; // Für Produktion wieder löschen
    const authorizer = (channel, options) => {
        return {
        authorize: (socketId, callback) => {
            axiosPrivate.post(options.authEndpoint,{channel_name: channel.name,socket_id: socketId},{withCredentials: true})
            .then((response) => {
                callback(null, response.data)
            })
            .catch((error) => {
                callback(new Error(`Error authenticating with server: ${error}`), {
                auth: ''
                })
            })
        }
        }
    }

    var pusher = new Pusher('83a78eb5a4b8deb458d7', {cluster: 'eu',authEndpoint:process.env.REACT_APP_BASE_URL+'/v1/pusherauth',authorizer: authorizer})

    var channel =  pusher.subscribe(pusherChannel)

    channel.bind("pusher:subscription_error", () => reject('unauthorized'))
    channel.bind("pusher:subscription_succeeded", () => resolve(channel))
    })
}

export default usePusher;